<template>
	<div>
		<span class="headline my-5">Informations Complémentaire</span>
		<v-card class="pa-5 mb-5 mt-2">

			<v-row>
				<v-col cols="4">
					<v-switch v-bind:disabled="!edit" v-model="data.contact.mail" label="Mail"></v-switch>
				</v-col>
				<v-col cols="4">
					<v-switch v-bind:disabled="!edit" v-model="data.contact.call" label="Appel"></v-switch>
				</v-col>
				<v-col cols="4">
					<v-switch v-bind:disabled="!edit" v-model="data.contact.sms" label="SMS"></v-switch>
				</v-col>
			</v-row>
		</v-card>

	</div>
</template>


<script>
	export default {
		props: {
			'data': {
				type: Object
			},

			'edit': {
				type: Boolean,
				default: false
			}
		}
	}
</script>