<template>
	<div>

		<span class="headline my-5">Informations Bancaires</span>
		<v-card class="pa-5 mb-5 mt-2">
			<v-row>
				<v-col cols="4" v-if="tvaConcerned">
					<v-switch v-bind:disabled="!edit" v-model="data.tva.status" label="Assujétti à la TVA"></v-switch>
				</v-col>

				<v-col cols="8" v-if="tvaConcerned">
					<v-text-field v-bind:disabled="!edit" label="Numéro TVA" v-if="data.tva.status" hint="Numéro de TVA intracommunautaire de l'entreprise" v-model="data.tva.number"></v-text-field>
				</v-col>

				<v-col cols="12">
					<v-row>
						<v-col cols="12" sm="6">
							<v-autocomplete v-bind:disabled="!edit" :items="this.$listUtils.bankCountries" v-model="data_.bank_info.country" label="Code Pays"></v-autocomplete>
						</v-col>
						<v-col cols="12" sm="6">
							<v-text-field v-bind:disabled="!edit" label="Banque" hint="Nom de la banque de l'entreprise" v-model="data_.bank_info.bank"></v-text-field>
						</v-col>
					</v-row>
					<v-text-field v-bind:disabled="!edit" label="IBAN / BIC" hint="IBAN/BIC de l'entreprise" v-model="data_.bank_info.iban"></v-text-field>
				</v-col>
			</v-row>
		</v-card>

	</div>
</template>


<script>
	export default {
		props: {
			'data': {
				type: Object
			},
			'tvaConcerned': {
				type: Boolean,
				default: false
			},

			'edit': {
				type: Boolean,
				default: false
			}
		},
		computed: {
			data_: function() {
				return {
					...this.$models.user,
					...this.$models.company,
					...this.data
				}
			}
		}
	}
</script>