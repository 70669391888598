<template>
	<div>
		<DataTable
			title="Remarques"
			type="remarks"
			v-if="remarks"
			noExpand
			:company="user.company"
			:headers="$tableHeaders.remarks"
			:items="remarks"
		>
			<v-btn
				right
				@click="dialog = true"
				outlined
				color="primary"
			>
				Ajouter une remarque
			</v-btn>
		</DataTable>

		<v-flex>
			<v-dialog
				v-model="dialog"
				transition="dialog-bottom-transition"
				max-width="960"
			>
				<v-card
					class="glassmorphism"
					color="transparent"
				>
					<ButtonTrigger
						title="Ajout d'une remarque"
						small
						whiteText
						:noButton="$vuetify.breakpoint.smAndUp"
						icon
						content=""
						@click.native="dialog = false"
					/>
					<v-col cols="12">
						<v-card class="bt-primary">
							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12">
											<v-form ref="form">
												<v-row>
													<v-col cols="12">
														<v-textarea
															v-model="remarkEntry.content"
															label="Contenu de la remarque"
															:rules="this.$fieldsRules.required('contenu')"
														></v-textarea>
													</v-col>
												</v-row>
											</v-form>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>
						</v-card>
					</v-col>
					<v-col
						cols="12"
						align="center"
						justify="center"
					>
						<v-card
							class="bt-success"
							height="110"
						>
							<v-card-text>
								<v-container>
									<v-row>
										<v-col cols="12">
											<v-form ref="form">
												<v-row>
													<v-col cols="12">
														<v-spacer></v-spacer>
														<v-btn
															:block="$vuetify.breakpoint.xsOnly"
															outlined
															color="success"
															class="neon evidence mb-4"
															width="200"
															@click="addRemark()"
														>
															Ajouter la remarque
														</v-btn>
													</v-col>
												</v-row>
											</v-form>
										</v-col>
									</v-row>
								</v-container>
							</v-card-text>
						</v-card>
					</v-col>
				</v-card>
			</v-dialog>
		</v-flex>

	</div>
</template>


<script>
	import DataTable from '@/components/modules/#global/DataTable';
	import ButtonTrigger from '@/components/graphics/buttonTrigger'

	export default {
		props: {
			'entity': {
				type: Object
			},
			'user': {
				type: Object
			},
			'type': {
				type: String
			},
		},
		components: {
			DataTable,
			ButtonTrigger
		},
		data() {
			return {
				dialog: false,

				remarkEntry: this.$models.remark,

				remarks: []
			}
		},
		watch: {
			entity() {
				this.init();
			}
		},
		methods: {
			addRemark() {
				if(!this.$refs.form.validate()) return;

				this.$db.collection("companies").doc(this.user.company).collection(this.type).doc(this.entity.id).collection("remarks").add({
					...this.remarkEntry,
					creationDate: new Date()
				}).then(() => {
					this.$refs.form.resetValidation();

					let message = `La remarque a été ajoutée à l'entreprise ${this.entity.name}.`;

					if(this.entity.firstName) message = `La remarque a été ajoutée à ${this.entity.firstName} ${this.entity.lastName[0].toUpperCase()}.`;
					
					this.$store.dispatch('setSnackbar', {
						text: message
					});

					this.remarkEntry.content = '';
					this.dialog = false;
				});
			},
			init() {
				this.remarkEntry.author = this.user.id;

				this.$db.collection("companies").doc(this.user.company).collection(this.type).doc(this.entity.id).collection("remarks").onSnapshot((ref) => {
					let tempRemarks = [];

					ref.forEach((remark) => {

						this.$db.collection("users").doc(remark.data().author).get().then((author) => {

							tempRemarks.push({
								...remark.data(),
								author: {
									...author.data(),
									id: author.id
								},
								id: remark.id
							});

						});

					});

					this.remarks = tempRemarks;
				});
			}
		},
		created() {
			this.init();
		}
	}

</script>
