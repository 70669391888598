<template>
	<v-flex>
		<v-dialog
			:value="value"
			@input="$emit('input')"
			transition="dialog-bottom-transition"
			max-width="960"
		>
			<v-card
				class="glassmorphism"
				color="transparent"
			>
				<ButtonTrigger
					title="Envoyer un mail"
					small
					whiteText
					:noButton="$vuetify.breakpoint.smAndUp"
					icon
					content=""
					@click.native="$emit('input', false)"
				/>
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<Title titleSize="h4">Contenu du mail</Title>
												</v-col>
												<v-col cols="12">
													<v-text-field
														label="Sujet du mail"
														v-model="mail.subject"
														:rules="this.$fieldsRules.required('sujet')"
													></v-text-field>
												</v-col>
												<v-col cols="12">
													<v-textarea
														v-model="mail.content"
														label="Corps du mail"
														:rules="this.$fieldsRules.required('contenu')"
													></v-textarea>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12">
					<v-card class="bt-primary">
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<Title titleSize="h4">Paramètres</Title>
												</v-col>
												<v-col cols="12">
													<v-row>
														<v-col>
															<v-checkbox
																v-model="mailOptions.receipt"
																label="Accusé de reception"
															></v-checkbox>
														</v-col>
														<v-col>
															<v-checkbox
																v-model="mailOptions.userCopy"
																label="Recevoir une copie"
															></v-checkbox>
														</v-col>
													</v-row>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col
					cols="12"
					align="center"
					justify="center"
				>
					<v-card
						class="bt-success"
						height="110"
					>
						<v-card-text>
							<v-container>
								<v-row>
									<v-col cols="12">
										<v-form ref="form">
											<v-row>
												<v-col cols="12">
													<v-spacer></v-spacer>
													<v-btn
														:block="$vuetify.breakpoint.xsOnly"
														outlined
														color="success"
														class="neon evidence mb-4"
														width="200"
														@click="submit()"
													>
														Envoyer le mail
													</v-btn>
												</v-col>
											</v-row>
										</v-form>
									</v-col>
								</v-row>
							</v-container>
						</v-card-text>
					</v-card>
				</v-col>
			</v-card>
		</v-dialog>
	</v-flex>
</template>


<script>
	import ButtonTrigger from '@/components/graphics/buttonTrigger';
	import Title from '@/components/graphics/title.vue';

	export default {
		name: "SendMailDialog",
		props: {
			'value': {
				type: Boolean,
				default: false
			},
			'entity': {
				type: Object
			},
			'user': {
				type: Object
			}
		},
		components: {
			ButtonTrigger,
			Title
		},
		data() {
			return {
				mail: {},

				mailOptions: {
					receipt: false,
					userCopy: false
				}
			};
		},

		methods: {
			async submit() {
				const recipients = {
					to: [this.entity.email]
				};

				if (this.mailOptions.userCopy) recipients.bcc = [this.user.email];

				this.$katana.sendMail(this.user.company, this.user.id, this.mail.subject, this.mail.content, recipients);

				this.mail = {};

				this.$emit('input', false);

				setTimeout(() => {
					this.$emit('refresh');
				}, 2500);

				this.$store.dispatch('setSnackbar', {
					text: `Le mail a bien été envoyé !`
				});
			}
		}
	};
</script>