<template>
	<div>
		<DataTable
			title="Mails"
			type="mails"
			v-if="mails"
			:company="user.company"
			:headers="$tableHeaders.mails"
			:items="mails"
		>
			<v-btn
				right
				@click="dialog = true"
				outlined
				color="primary"
			>
				Envoyer un mail
			</v-btn>
		</DataTable>

		<SendMailDialog
			v-model="dialog"
			:entity="entity"
			:user="user"
			@refresh="refresh()"
		/>
	</div>
</template>


<script>
	import DataTable from '@/components/modules/#global/DataTable';
	import SendMailDialog from '@/components/modules/@mails/SendMailDialog';

	export default {
		props: {
			'entity': {
				type: Object
			},
			'user': {
				type: Object
			}
		},
		components: {
			DataTable,
			SendMailDialog
		},
		data() {
			return {
				dialog: false,

				remarkEntry: this.$models.remark,

				mails: []
			}
		},
		methods: {
			refresh() {
				this.$katana.getMails(this.entity.email, "receiver", true).then((data) => {
					this.mails = data;
				});
			}
		},
		created() {
			this.refresh();
		}
	}
</script>
